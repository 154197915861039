<template>
  <v-flex>
    <DefaultLoading v-if="loading" :loading="loading" />
    <div v-if="!loading && !error" class="pdf-wrapper">
      <vue-pdf-app :pdf="pdfContent" :file-name="fileName" page-scale="page-width" v-pdf-app-load></vue-pdf-app>
    </div>
  </v-flex>
</template>

<script>
import { SignaturesService } from "../../services/signatures-service";
import DefaultLoading from "./DefaultLoading.component";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
  name: "auth-user-pdf-viewer",
  components: { VuePdfApp, DefaultLoading },
  props: ["token", "authId", "requestId", "fileName"],
  data: () => ({
    currentPage: 0,
    pageCount: 0,
    loading: false,
    error: false,
    pdfContent: null,
  }),

  mounted() {
    this.loadDocument();
  },
  watch: {
    requestId() {
      this.loadDocument();
    },
  },
  methods: {
    loadDocument() {
      this.error = false;
      if (this.requestId) {
        this.loading = true;
        SignaturesService.getCertificateSignatureRequestDocument(
          this.requestId,
          this.token,
          this.authId
        )
          .then((response) => {
            this.$emit("documentError", false);
            this.pdfContent = response.data;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.status == 404) {
              this.error = true;
              this.$emit("documentError", true);
            }
          });
      } else {
        this.error = true;
        this.$emit("documentError", true);
      }
    },
  },
};
</script>
