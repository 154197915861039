<template>
	<v-overlay v-model="dialog" color="#fff" opacity="1">
		<div class="single-document-summary-wrapper pa-4 fill-width">
			<v-row class="pt-12">
				<div class="col-md-12 col-sm-12">
					<Alerts v-if="showAlert" :alertType="alertType" :alertMsg="alertMsg" />
				</div>
			</v-row>
			<div id="single-document-summary">
				<v-container v-if="!loading" class="fill-width">
					<div id="single-doc-summary">
						<v-card outlined class="px-3" light>
							<v-row>
								<div class="col-md-12 col-xs-12">
									<v-list two-line class="pa-0">
										<v-list-item background-color="white" class="white">
											<v-list-item-content class="pa-0">
												<v-list-item-title class="mb-1">
													<h3>{{ title }}</h3>
												</v-list-item-title>
												<v-list-item-subtitle class="color_gray--text font-weight-medium">
													{{ signatureTransactionData.ownerName }}
												</v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
										<v-list-item class="white">
											<h4>{{ $t("signers") }}</h4>
											<v-avatar color="#F36E21" class="ml-6" size="20" min-width="20">
												<span class="white--text">{{ signatureCount }}</span>
											</v-avatar>
										</v-list-item>
									</v-list>
								</div>
							</v-row>
							<v-row>
								<div class="col-md-4 col-sm-12" v-for="(item, index) in signatureRequestList" :key="index">
									<v-list class="pa-0">
										<v-list-item class="white">
											<v-list-item-content class="pa-0">
												<v-list-item-subtitle class="color_gray--text font-weight-medium">
													{{ item.name }}
												</v-list-item-subtitle>
												<p>{{ moment(item.date).format("DD/MM/YYYY H:m") }}</p>
												<p>{{ item.comments }}</p>
											</v-list-item-content>
											<v-list-item-action>
												<v-icon v-if="item.status === 'SIGNED'"
													:color="getIconColorByStatus('SIGNED')">
													mdi-checkbox-marked-circle-outline</v-icon>
												<v-icon v-if="item.status === 'REJECTED'"
													:color="getIconColorByStatus('REJECTED')">
													mdi-close-outline</v-icon>
												<v-icon v-if="item.status == 'NOTIFIED'"
													:color="getIconColorByStatus('NOTIFIED')">
													mdi-clock</v-icon>
											</v-list-item-action>
										</v-list-item>
									</v-list>
								</div>
							</v-row>
						</v-card>
					</div>
				</v-container>

				<DefaultLoading v-if="loading" :loading="loading" />
			</div>

			<v-row class="d-flex justify-center">
				<div class="col-md-6 col-sm-6 col-xs-12 mt-5">
					<v-btn v-if="isApproved" block large color="#F36E21" :loading="loadingDownload" dark @click="download()">
						{{ $t("download") }}
					</v-btn>
				</div>

				<div class="col-md-6 col-sm-6 col-xs-12 mt-5">
					<v-btn :to="`/${hasAssociation ? (currentRoute.params.association + '/') : ''}document-list`" block large>
						{{ $t("back_to_home") }}
					</v-btn>
				</div>
			</v-row>
		</div>
	</v-overlay>
</template>

<script>
import Alerts from "../../components/shared/Alerts.component";
import { SignaturesService } from "../../services/signatures-service";

import moment from "moment";
export default {
	name: "single-document-summary",
	components: { Alerts },
	props: {
		dialog: Boolean,
		showAlert: Boolean,
		alertType: String,
		alertMsg: String,
		requestId: Number,
		authId: String,
		isApproved: Boolean,
	},
	data: function () {
		return {
			appendFils: false,
			title: "",
			document: [],
			signatureTransactionData: [],
			signatureRequestList: [],
			signatureCount: 0,

			transactionId: "",
			loading: false,
			loadingDownload: false,
		};
	},

	created: function () {
		this.moment = moment;
	},
	watch: {
		requestId() {
			this.loadRequestData();
		},
	},

	computed: {
		hasAssociation() {
			return this.$store.getters['common/hasAssociation']
		},
		currentRoute() {
			return this.$route;
		}
	},

	methods: {
		changeAppendFileShow() {
			this.appendFils = !this.appendFils;
		},
		loadRequestData() {
			if (this.requestId) {
				this.loading = true;
				SignaturesService.getCertificateSignatureDocument(
					localStorage.getItem("auth_user_token"),
					this.requestId
				)
					.then((response) => {
						this.signatureTransactionData = response.data.signatureTransaction;
						this.document = response.data;
						this.title = this.signatureTransactionData.document.title;
						this.transactionId = this.signatureTransactionData.document.id;
						if (this.document.status == "SIGNED" || this.document.status == "REJECTED") {
							this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}document-list` });
						}
						this.createRequestList(response.data);
						this.loading = false;
					})
					.catch(() => {
						this.loading = false;
					});
			}
		},
		createRequestList(data) {
			var vm = this;
			vm.signatureRequestList.push({
				name: data.signerName,
				date: data.dateAction,
				status: data.status,
				comments: data.comments,
			});

			if (data.signatureTransaction.signatureRequests.length > 1) {
				data.signatureTransaction.signatureRequests.map(function (item) {
					if (item.signerName && item.signerName !== "") {
						vm.signatureRequestList.push({
							name: item.signerName,
							date: item.dateAction,
							status: item.status,
							comments: item.comments,
						});
					}
				});
			}
			this.signatureCount = this.signatureRequestList.length;
		},

		download() {
			if (this.transactionId) {
				this.loadingDownload = true;
				SignaturesService.getCertificateSignatureRequestDocument(
					this.requestId,
					localStorage.getItem("auth_user_token"),
					this.authId
				)
					.then((response) => {
						const file = new Blob([response.data], { type: "application/pdf" });
						const fileURL = URL.createObjectURL(file);
						const a = document.createElement("a");
						a.href = fileURL;
						a.download = localStorage.getItem("file_name");
						a.click();
						window.URL.revokeObjectURL(fileURL);
						this.loadingDownload = false;
					})
					.catch((error) => {
						console.log(error);
						this.loadingDownload = false;
					});
			}
		},

		getIconColorByStatus(type) {
			let color = "";
			switch (type) {
				case "SIGNED":
					color = "#4CAF50";
					break;

				case "REJECTED":
					color = "#970606";
					break;

				case "NOTIFIED":
					color = "#E0E0E0";
					break;
			}
			return color;
		},
	},
};
</script>
<style scoped>
.fill-width {
	width: 100%;
}
</style>