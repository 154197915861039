<template>
  <div id="pageAlerts">
    <v-alert border="left" type="success" v-if="alertType == 'success'"
      >{{ alertMsg }}
    </v-alert>
    <v-alert border="left" type="error" v-else-if="alertType == 'error'"
      >{{ alertMsg }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "pageAlerts",
  props: {
    alertType: String,
    alertMsg: String,
  },
  data: function() {
    return {};
  },
  methods: {},
};
</script>
